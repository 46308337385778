import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Footer.css"; // Import the new CSS file

const Footer = ({ scrollToTop }) => {
  const COMPANY_NAME = "Krishabh Technology";
  const CURRENT_YEAR = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4} className="footer-col">
            <h3>Krishabh Technology</h3>
            <p>
              <strong>Office Address:</strong>
            </p>
            <p>
              28/A/406, Niwara Apartments, Farm Road
              <br /> Chandivali, Andheri East, Mumbai-400072
            </p>
            <p>Email: krishabhtechnology@gmail.com</p>
            <p>Phone: +91-7387195791</p>

            <div className="d-flex pt-2">
              <a
                className="btn btn-square btn-outline-secondary rounded-circle me-1"
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-facebook"></i>
              </a>
              <a
                className="btn btn-square btn-outline-secondary rounded-circle me-1"
                href="https://www.youtube.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-youtube"></i>
              </a>
              <a
                className="btn btn-square btn-outline-secondary rounded-circle me-0"
                href="https://www.linkedin.com/in/krishabh-technology-971791322"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </Col>
          <Col md={4} className="footer-col">
            <h5>Useful Links</h5>
            <ul>
              <li>
                <Link to="/" onClick={scrollToTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={scrollToTop}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/services" onClick={scrollToTop}>
                  Services
                </Link>
              </li>
              <li>
                <Link to="/portfolio" onClick={scrollToTop}>
                  Portfolio
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={scrollToTop}>
                  Contact
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={4} className="footer-col">
            <h5>Our Services</h5>
            <ul>
              <li>
                <Link to="/website-design" onClick={scrollToTop}>
                  Website Design
                </Link>
              </li>
              <li>
                <Link to="/website-development" onClick={scrollToTop}>
                  Website Development
                </Link>
              </li>
              <li>
                <Link to="/mobile-app-development" onClick={scrollToTop}>
                  Mobile App Development
                </Link>
              </li>
              <li>
                <Link to="/website-maintenance" onClick={scrollToTop}>
                  Website Maintenance
                </Link>
              </li>
              <li>
                <Link to="/graphic-design" onClick={scrollToTop}>
                  Graphic Design
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p>
              &copy; {CURRENT_YEAR} {COMPANY_NAME}. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
