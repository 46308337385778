import React from "react";
import { Button } from "react-bootstrap";
import "./SharedButton.css"; // Optional for additional styles

const SharedButton = ({
  variant = "primary",
  type = "button",
  className = "kr-primary-button",
  onClick,
  children,
}) => {
  return (
    <Button
      variant={variant}
      type={type}
      className={className}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default SharedButton;
