import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import testimonialPic1 from "../assets/images/kr-testimonial-icon1.png";
import testimonialPic2 from "../assets/images/kr-testimonial-icon2.png";
import testimonialPic3 from "../assets/images/kr-testimonial-icon3.png";
const testimonials = [
  {
    name: "Dr. Aslam Pathan",
    position: "Founder & CEO, Neuropharmac Journal",
    image: testimonialPic1,
    text: "Professional, efficient, and top-notch service from start to finish.",
  },
  {
    name: "Kumar Aditya",
    position: "Founder, Kavya Books Store",
    image: testimonialPic2,
    text: "Krishabh has provided exceptional service. Highly recommend!",
  },
  {
    name: "Dr. Ashok Shyam",
    position: "Founder- Journal of Orthopaedic Case Reports",
    image: testimonialPic3,
    text: "The Krishabh team showcased outstanding professionalism and technical expertise.",
  },
  // Add more testimonials as needed
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1
  },
};

const Testimonials = () => {
  return (
    <section className="testimonials">
      <Container>
        <h2 className="section-title">What Our Clients Say</h2>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={5000}
          infinite={true}
          swipeable={true}
          draggable={true}
          showDots={true}
          keyBoardControl={true}
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <img
                className="testimonial-image"
                src={testimonial.image}
                alt={testimonial.name}
              />
              <blockquote className="testimonial-text">
                "{testimonial.text}"
              </blockquote>
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-position">{testimonial.position}</p>
            </div>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};

export default Testimonials;
