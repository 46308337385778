import React from "react";
import missionIcon from "../assets/images/kr-mission-icon.png";
import visionIcon from "../assets/images/kr-vision-icon.png";
import valuesIcon from "../assets/images/kr-values-icon.png";
import teamMember1 from "../assets/images/kr-our-team-pic.jpg";
import founderPic from "../assets/images/kr-founder-pic.jpg";
import "./About.css"; // Import the CSS file
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../components/CommonBanner";
import IntroSection from "../components/common/IntroSection";
import HelmetWrapper from "../components/common/HelmetWrapper";
import "bootstrap-icons/font/bootstrap-icons.css";

const teamMembers = [
  {
    name: "Mr. Ashish Kumar",
    role: "Founder & CEO",
    image: founderPic,
    bio: "Ashish is a visionary leader with a proven track record in driving innovation and excellence within the industry. With a passion for fostering creativity and strategic thinking, he guides his team to achieve exceptional results and create impactful solutions.",
  },
  {
    name: "Shivam Yadav",
    role: "Software Engineer",
    image: teamMember1,
    bio: "Shivam is the tech guru, overseeing our technological advancements and strategies.",
  },
  {
    name: "Abhishek Singh",
    role: "UI/UX Designer",
    image: teamMember1,
    bio: "Abhishek is dedicated to creating intuitive and engaging user experiences, ensuring our designs are both visually appealing and user-friendly.",
  },
  {
    name: "Ravi Kumar",
    role: "Senior WordPress Developer",
    image: teamMember1,
    bio: "Ravi is a seasoned WordPress developer with extensive expertise in creating dynamic, responsive, and user-friendly websites. His meticulous approach ensures top-notch performance, seamless functionality, and visually appealing designs that align with clients' visions and goals.",
  },
];

const About = () => {
  return (
    <div className="about-page">
      <HelmetWrapper title="About Us" />
      <CommonBanner title="About Us" />
      <IntroSection
        title="About the Krishabh"
        description="We bring you the finest services and solutions tailored to meet your needs. Our team of experts is dedicated to ensuring your success through innovative and customized approaches."
      />
      <section className="about-section">
        <Container>
          <Row>
            <Col md={4}>
              <div className="about-item">
                <img src={missionIcon} alt="Mission" className="about-icon" />
                <h3>Our Mission</h3>
                <p>
                  Our mission is to empower our clients with solutions that
                  drive their success.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="about-item">
                <img src={visionIcon} alt="Vision" className="about-icon" />
                <h3>Our Vision</h3>
                <p>
                  Our vision is to lead in innovative solutions that prioritize
                  quality and satisfaction.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="about-item">
                <img src={valuesIcon} alt="Values" className="about-icon" />
                <h3>Our Values</h3>
                <p>Integrity, excellence, and teamwork guide all that we do.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="team-section">
        <Container>
          <h2>Our Team</h2>
          <Row>
            {teamMembers.map((member, index) => (
              <Col md={4} key={index} className="mb-4">
                <div className="team-member">
                  {member.image && (
                    <img
                      src={member.image}
                      alt={member.name}
                      className="team-member-image"
                    />
                  )}
                  <h3 className="team-member-name">{member.name}</h3>
                  <p className="team-member-role">{member.role}</p>
                  <p className="team-member-bio">{member.bio}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default About;
