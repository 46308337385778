import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import welcomePic1 from "../assets/images/kr-welcome-pic1.jpg";
import welcomePic2 from "../assets/images/kr-welcome-pic2.jpg";
import welcomeTrophyPic from "../assets/images/kr-welcome-pic4.png";
import "./WelcomeSection.css";

const WelcomeSection = () => {
  return (
    <section className="welcome-section">
      <Container>
        <Row>
          <Col md={6} className="image-col">
            <div className="welcome-left-section">
              <img
                src={welcomePic1}
                alt="Welcome Pic1"
                className="left-pic1 img-fluid d-none d-xxl-inline-block rounded-3"
              />
              <img
                src={welcomePic2}
                alt="Welcome Pic2"
                className="left-pic2 img-fluid d-none d-xxl-inline-block rounded-3"
              />
              <div className="experience-box2">
                <div className="inner-content">
                  <img src={welcomeTrophyPic} alt="icon" />
                  <h3>10</h3>
                  <p>Years Experience</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="text-col">
            <h2>Welcome to Krishabh</h2>
            <p>
              We bring you the finest services and solutions tailored to meet
              your needs. Our team of experts is dedicated to ensuring your
              success through innovative and customized approaches.
            </p>
            <p>
              At Krishabh, we believe in transforming challenges into
              opportunities. Whether you’re a growing business or an established
              enterprise, we partner with you to deliver seamless solutions that
              align with your vision and objectives.
            </p>
            <h3>Why Choose Us?</h3>
            <ul>
              <li>
                <strong>Customized Solutions</strong>: Every client is unique,
                and so are our strategies. We craft solutions that perfectly fit
                your requirements.
              </li>
              <li>
                <strong>Expert Team</strong>: Our professionals are skilled,
                experienced, and passionate about driving results.
              </li>
              <li>
                <strong>Innovative Approaches</strong>: We combine creativity
                and technology to deliver cutting-edge solutions.
              </li>
              <li>
                <strong>Client-Centric Approach</strong>: Your satisfaction is
                our priority. We work closely with you at every step to ensure
                excellence.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WelcomeSection;
