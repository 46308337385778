// src/components/PortfolioSection.js
import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import SharedButton from "./common/SharedButton";

const PortfolioSection = ({ portfolioItems, title }) => {
  return (
    <section className="portfolio-section">
      <Container>
        {title && <h2 className="section-title">{title}</h2>}
        <Row>
          {portfolioItems.map((item, index) => (
            <Col md={4} key={index}>
              <Card className="portfolio-card">
                <Card.Img variant="top" src={item.image} alt={item.title} />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text className="tech-stack">
                    <strong>Technologies Used:</strong> {item.techStack}
                  </Card.Text>
                  {item.comingSoon ? (
                    <span className="coming-soon-text">Coming Soon</span>
                  ) : (
                    <SharedButton
                      variant="primary"
                      onClick={() =>
                        window.open(item.url, "_blank", "noopener,noreferrer")
                      }
                    >
                      View Project
                    </SharedButton>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PortfolioSection;
