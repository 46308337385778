// src/pages/Portfolio.js
import React from "react";
import { Container } from "react-bootstrap";
import HelmetWrapper from "../components/common/HelmetWrapper";
import CommonBanner from "../components/CommonBanner";
import PortfolioSection from "../components/PortfolioSection";
import project1 from "../assets/images/kr-project-01-pic.jpg";
import project2 from "../assets/images/kr-project-02-pic.jpg";
import project3 from "../assets/images/kr-project-03-pic.jpg";
import project4 from "../assets/images/kr-project-04-pic.jpg";
import project5 from "../assets/images/kr-project-05-pic.jpg";

const portfolioItems = [
  {
    title: "Kavya Books Store",
    image: project1,
    url: "https://kavyabooksstore.com",
    techStack:
      "WordPress, Bootstrap, WooCommerce, PHP, MYSQL, Google Analytics",
  },
  {
    title: "Journal of Orthopaedic Case Reports",
    image: project2,
    url: "https://jocr.co.in",
    techStack: "WordPress, Bootstrap, PHP, MYSQL, Google Analytics",
  },
  {
    title: "Urmila's Beauty Parlour & Academy",
    image: project3,
    comingSoon: true,
    techStack: "React JS, Next JS, Bootstrap, PHP, MYSQL, Firebase",
  },
  {
    title: "NeuroPharmac Journal",
    image: project4,
    url: "https://www.neuropharmac.com",
    techStack: "WordPress, Bootstrap, PHP, MYSQL, Google Analytics",
  },
  {
    title: "Om Caterers",
    image: project5,
    comingSoon: true,
    techStack: "WordPress, Bootstrap, PHP, MYSQL, Google Analytics",
  },
];

const Portfolio = () => {
  return (
    <div className="portfolio-page">
      <HelmetWrapper title="Portfolio" />
      <CommonBanner title="Portfolio" />
      <Container>
        <PortfolioSection
          portfolioItems={portfolioItems}
          title="Our Portfolio"
        />
      </Container>
    </div>
  );
};

export default Portfolio;
