// src/pages/Home.js
import React from "react";
import HomePageBanner from "../components/HomePageBanner";
import WelcomeSection from "../components/WelcomeSection";
import MissionVisionValues from "../components/MissionVisionValues";
import PortfolioSection from "../components/PortfolioSection";
import Testimonials from "../components/Testimonials";
import HelmetWrapper from "../components/common/HelmetWrapper";
import project1 from "../assets/images/kr-project-01-pic.jpg";
import project2 from "../assets/images/kr-project-02-pic.jpg";
import project4 from "../assets/images/kr-project-04-pic.jpg";

const homePortfolioItems = [
  {
    title: "Kavya Books Store",
    image: project1,
    url: "https://kavyabooksstore.com",
    techStack: "WordPress, Bootstrap, WooCommerc, Google Analytics",
  },
  {
    title: "Journal of Orthopaedic Case Reports",
    image: project2,
    url: "https://jocr.co.in",
    techStack: "WordPress, Bootstrap, PHP, MYSQL, Google Analytics",
  },
  {
    title: "NeuroPharmac Journal",
    image: project4,
    url: "https://www.neuropharmac.com",
    techStack: "WordPress, Bootstrap, PHP, MYSQL, Google Analytics",
  },
];

const Home = () => {
  return (
    <div>
      <HelmetWrapper title="Home" />
      <HomePageBanner />
      <WelcomeSection />
      <MissionVisionValues />
      <PortfolioSection
        portfolioItems={homePortfolioItems}
        title="Latest Projects"
      />
      <Testimonials />
    </div>
  );
};

export default Home;
